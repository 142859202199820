.creditDialogTitle {
  text-align: center;
}

.creditForm {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .currencyInputWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .currencyInput {
      width: 150px;
    }

    .currencySymbol {
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
