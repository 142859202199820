@use 'styles/mixins.scss' as *;

.userInfo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  margin: 10px;

  .userName {
    margin-right: 20px;
    font-weight: bold;
    cursor: pointer;
    @include adaptive-font(16, 14);

    &_withoutUserManagementPermission {
      cursor: default;
    }
  }

  .creditFun {
    @include adaptive-font(14, 12);
  }
}

@media (max-width: 1199px) {
  .userInfo {
    flex-direction: column;

    .userName {
      margin: 0;
    }
  }
}

@media (max-width: 767px) {
  .userInfo {
    min-width: 320px;
    flex-direction: row;
  }
}

@media (max-width: 576px) {
  .userInfo {
    .userName {
      display: none;
    }

    .creditFun {
      font-size: 12px;
    }
  }
}

.demoUserInterface {
  @media (max-width: 767px) {
    min-width: 75px;
  }

  @media (max-width: 576px) {
    .userName {
      display: block;
    }
    .creditFun {
      font-size: 12px;
    }
  }
}
