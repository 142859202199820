@use 'styles/mixins.scss' as *;

.creditReal {
  @include adaptive-font(14, 12);
}

.currencyMenuList {
  max-height: 300px;
  overflow-y: auto;
}

@media (max-width: 576px) {
  .userInfo {
    .creditFun {
      font-size: 12px;
    }
  }
}
