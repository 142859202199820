@use 'styles/mixins' as *;

.controlPanel {
  display: flex;
  flex-direction: column;
  align-items: center;

  .info {
    margin-top: 20px;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .gameNameBlock {
      padding: 0;
    }

    .title {
      @include adaptive-font(20, 16);
    }

    .gameClass {
      margin: 10px 0 10px 0;
      @include adaptive-font(16, 14);
    }
  }

  .selectGroup {
    margin-top: 10px;
    width: 95%;
    display: flex;
    justify-content: space-between;
    gap: 5px;

    .formControlAndTextField {
      width: 120px;
    }
  }
}
