@use 'styles/variables.scss' as *;

.filterPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .switchers {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .selectGroup {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .environmentControl {
    margin: 10px 0 20px 0;
  }
}

@media (max-width: 767px) {
  .filterPanel {
    flex-direction: column;
    .switchers {
      margin-bottom: 25px;
    }
  }
}

@media (max-width: 576px) {
  .filterPanel {
    .switchers {
      width: 280px;
      display: flex;
      justify-content: space-between;
      label {
        display: flex;
        flex-direction: column-reverse;
        margin: 0;
        span {
          font-size: 15px;
        }
      }
    }
    .selectGroup {
      gap: 20px;
    }
  }
}
