.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  padding-bottom: 10px;
  gap: 10px;

  .textField {
    width: 220px;
  }
}
