.dialogTitle {
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .formContent {
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;

    .formInput {
      width: 200px;
    }

    .fieldForm {
      width: 200px;
    }
  }
}

@media (max-width: 767px) {
  .formContent {
    width: 450px;
  }
}

@media (max-width: 576px) {
  .formContent {
    width: 200px;
    flex-direction: column;
    align-items: center;

    .formInput {
      width: 200px;
    }
  }
}
