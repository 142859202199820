.roleDialogTitle {
  text-align: center;
}

.roleForm {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.fieldForm {
  width: 100%;
}

.permissionsField {
  margin-top: 15px;
}

.roleFormInput {
  width: 100%;
}
