.tagDialogTitle {
  text-align: center;
}

.tagForm {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.fieldForm {
  width: 100%;
}

.tagFormInput {
  width: 100%;
}
