.btnGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .iconBtn {
    &:hover {
      background-color: transparent;
    }
  }
  .iconAdmin {
    height: 2rem;
    width: 2rem;
    padding-top: 0.3rem;
    color: #fff;
  }

  .iconLogout {
    height: 2rem;
    width: 2rem;
    padding-left: 0.8rem;
    &:hover {
      background-color: transparent;
    }
  }

  .burger {
    display: none;
  }

  &.baseInterface {
    @media (max-width: 576px) {
      width: 150px;

      .iconBtn {
        padding: 0;

        .iconLogout {
          padding: 0;
          width: 35px;
          height: 35px;
        }
      }

      .burger span {
        position: absolute;
        background-color: #fff;
        left: 0;
        width: 100%;
        height: 2px;
        top: 9px;
        transition: all 0.3s ease 0s;
      }

      .burger:before,
      .burger:after {
        content: '';
        background-color: #fff;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        transition: all 0.3s ease 0s;
      }

      .burger {
        display: block;
        position: relative;
        width: 30px;
        height: 20px;

        &:before {
          top: 0;
        }

        &:after {
          bottom: 0;
        }

        &_active:before {
          transform: rotate(45deg);
          top: 9px;
        }
        &_active:after {
          transform: rotate(-45deg);
          bottom: 9px;
        }

        &_active span {
          transform: scale(0);
        }
      }
    }
  }
}
