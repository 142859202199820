@use 'styles/variables.scss' as *;

.tagsCell {
  width: 600px;
}

.newTagText {
  margin-left: 4px;
  color: $main-color;
}
